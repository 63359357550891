import {Box, Typography} from "@mui/material";
import {ButtonColorInvert} from "../comp";

const BurgerBees = () => {

    return (
        <>
            <Box
                component="span"
                sx={{
                    position: 'fixed',
                    background: 'black',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '25vw',
                    lineHeight: '20vw',
                    fontWeight: '400',
                    color: 'rgb(0,35,35)',
                    zIndex: -1,
                    fontFamily: "'Anonymous Pro', monospace",
                    textAlign: 'center'
                }}
            >
                BURGER BEES
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: '1rem', md: '2rem'},
                margin: {xs: '4rem 1rem', md: '4rem 2rem'},
                color: 'white',
                fontSize: '1rem',
                fontFamily: "'Roboto', sans-serif"
            }}>
                <Typography variant={'h1'} sx={{fontFamily: "'Anonymous Pro', monospace"}}>BURGER BEES</Typography>
                <Typography variant={'subtitle1'} sx={{color: '#c4c9c9'}}>
                    Webshop und Managementsystem für eine Hobbyimkerei
                </Typography>
                <Typography sx={{
                    maxWidth: '50rem',
                    textAlign: 'block',
                }}>

                </Typography>

                <Box sx={{fontFamily: "'Anonymous Pro', monospace", display: 'flex'}}>
                    <ButtonColorInvert to="https://www.burger-bees.de"
                                       text={"SHOP NOW"}
                                       target='_blank'
                                       href="https://www.burger-bees.de"/>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>FUNKTIONEN</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>

                    </Box>

                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/keyman_.png'} alt={"keyman_.png"} className={"card-img"}/>
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>UI</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/keyman_.png'} alt={"keyman_.png"} className={"card-img"}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>

                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>API</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1,
                        gap: '.5rem'
                    }}>

                    </Box>

                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/keyman_.png'} alt={"keyman_.png"} className={"card-img"}/>
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>TEAM</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        flex: 2,
                        height: 'max-content',
                        flexDirection: {xs: 'column', md: 'row'},
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={'/keyman_.png'} alt={"keyman_.png"} className={"card-img"}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>

                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>DATENBANK</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        BurgerBees verwendet eine PostgreSQL-Datenbank.
                    </Box>
                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/burger-bees_dbschema.png'} alt={"burger-bees_dbschema.png"} className={"card-img"}/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default BurgerBees;