import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Input,
    InputLabel
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import './comp.css';

const styles = theme => ({
    FormControl: {
        color: "pink"
    },
    Input: {}
})

const ContactSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .min(4, 'Vorname zu kurz!')
        .max(30, 'Vorname zu lang!')
        .required('erforderlich')
    ,
    lastName: Yup.string()
        .trim()
        .min(4, 'Nachname zu kurz!')
        .max(30, 'Nachname zu lang!')
        .required('erforderlich')
    ,
    email: Yup.string()
        .email('Ungültige Email!')
        .max(60, 'zu lang!')
    // .required('erforderlich')
    ,
    phoneNumber: Yup.string()
        .trim()
        .matches(/^[+/\d\s]+$/, "keine gültige Telefonnummer")
        .min(8, 'zu kurz!')
        .max(30, 'zu lang!')
    ,
    message: Yup.string()
        .trim()
});

const ContactForm = () => {
    const nav = useNavigate();
    const user = {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        message: "",
    }

    const trimAll = (v) => {
        for (let key in v) {
            if (v[key] !== null && typeof v[key] === "string") v[key] = v[key]?.trim();
        }
    }

    const handleSubmit = async (values) => {
        trimAll(values);

        setTimeout(() => {
            nav("#");
        }, 5000)
    }

    return (
        <Formik
            validationSchema={ContactSchema}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            initialValues={user}>
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
              }) => (
                <Form onSubmit={handleSubmit}>

                    {/* given name */}
                    <FormControl fullWidth
                                 onBlur={handleBlur}
                                 sx={{marginBottom: 1.5, color: "white"}}
                                 error={touched.firstName && !!errors.firstName}
                                 size={"small"}>
                        <InputLabel>Vorname</InputLabel>
                        <Input
                            type={"text"}
                            id={"firstName"}
                            autoComplete="given-name"
                            value={values.firstName}
                            onChange={handleChange}/>
                        <FormHelperText>{touched.firstName ? errors.firstName?.toString() : null}</FormHelperText>
                    </FormControl>

                    {/* family name*/}
                    <FormControl fullWidth
                                 onBlur={handleBlur}
                                 sx={{marginBottom: 1.5}}
                                 error={touched.lastName && !!errors.lastName}
                                 size={"small"}>
                        <InputLabel>Nachname</InputLabel>
                        <Input
                            type={"text"}
                            fullWidth
                            id={"lastName"}
                            autoComplete="family-name"
                            value={values.lastName}
                            onChange={handleChange}/>
                        <FormHelperText>{touched.lastName ? errors.lastName?.toString() : null}</FormHelperText>
                    </FormControl>

                    {/* email */}
                    <FormControl fullWidth
                                 onBlur={handleBlur}
                                 sx={{marginBottom: 1.5}}
                                 error={touched.email && !!errors.email}
                                 size={"small"}>
                        <InputLabel required>Email</InputLabel>
                        <Input
                            type={"email"}
                            id={"email"}
                            autoComplete="email"
                            value={values.email}
                            onChange={handleChange}/>
                        <FormHelperText>{touched.email ? errors.email?.toString() : null}</FormHelperText>
                    </FormControl>

                    {/* phoneNumber */}
                    <FormControl fullWidth
                                 onBlur={handleBlur}
                                 sx={{marginBottom: 1.5}}
                                 error={touched.phoneNumber && !!errors.phoneNumber}
                                 size={"small"}>
                        <InputLabel>Telefonnummer</InputLabel>
                        <Input
                            type={"text"}
                            id={"phoneNumber"}
                            autoComplete="tel"
                            value={values.phoneNumber}
                            onChange={handleChange}/>
                        <FormHelperText>{touched.phoneNumber ? errors.phoneNumber?.toString() : null}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth
                                 onBlur={handleBlur}
                                 sx={{marginBottom: 1.5}}
                                 error={touched.message && !!errors.message}
                                 size={"small"}>
                        <InputLabel>Nachricht</InputLabel>
                        <Input
                            multiline
                            type={"text"}
                            id={"message"}
                            value={values.message}
                            onChange={handleChange}/>
                        <FormHelperText>{touched.message ? errors.message?.toString() : null}</FormHelperText>
                    </FormControl>

                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Button className="registration-btn"
                                disabled={!isValid}
                                type="submit"
                                variant={"outlined"}
                                color={"inherit"}>
                            Send Mail
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

export default ContactForm;