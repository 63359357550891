import {Box, Typography} from "@mui/material";
import {ButtonColorInvert} from "../comp";

const Tms = () => {

    return (
        <>
            <Box
                component="span"
                sx={{
                    position: 'fixed',
                    background: 'black',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '45vw',
                    lineHeight: '20vw',
                    fontWeight: '400',
                    color: 'rgb(0,35,35)',
                    zIndex: -1,
                    fontFamily: "'Anonymous Pro', monospace"
                }}
            >
                TMS
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: '1rem', md: '2rem'},
                margin: {xs: '4rem 1rem', md: '4rem 2rem'},
                color: 'white',
                fontSize: '1rem',
                fontFamily: "'Roboto', sans-serif"
            }}>
                <Typography variant={'h1'} sx={{fontFamily: "'Anonymous Pro', monospace"}}>TMS</Typography>
                <Typography variant={'subtitle1'} sx={{color: '#c4c9c9'}}>
                    Time Management / Tracking Software - Fullstack
                </Typography>
                <Typography sx={{
                    maxWidth: '50rem',
                    textAlign: 'block',
                }}>
                    TMS, kurz für Time Management System, ist ein innovatives Softwareprodukt, das als erstes
                    Universitätsprojekt im Rahmen der Vorlesung Softwaretechnik entwickelt wurde. Es handelt sich um
                    eine Zeiterfassungssoftware der fiktiven Firma "STC BANK AG", die darauf abzielt, die
                    Produktivität
                    im Unternehmen zu steigern, indem sie genaue und effiziente Zeitverfolgungsmechanismen
                    bietet.
                </Typography>

                <Box sx={{fontFamily: "'Anonymous Pro', monospace", display: 'flex'}}>
                    <ButtonColorInvert to="https://tms.jbyte.de"
                                       text={"JUST TRY IT"}
                                       target='_blank'
                                       href="https://tms.jbyte.de"/>
                </Box>

                <Typography>
                    <b>HR:</b> username: lena | <b>Manager:</b> username: bob | <b>User:</b> username: alice <br/>
                    password: password
                </Typography>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>FUNKTIONEN</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        Die Hauptfunktion von TMS ist die Erfassung, Analyse und Berichterstattung der Arbeitszeit von
                        Mitarbeitern. Es ermöglicht den Nutzern, ihre Zeit für verschiedene Aufgaben und Projekte zu
                        erfassen und zu verwalten, was wiederum hilft, die Arbeitsleistung zu überwachen und zu
                        optimieren.
                        Darüber hinaus bietet TMS Funktionen zur Verwaltung von Urlauben, Krankheitstagen und
                        Überstunden,
                        was zur Verbesserung der Arbeitsplanung und Personalverwaltung beiträgt.
                    </Box>

                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/tms_timesheet_new.png'} alt={"tms_timesheet_new.png"} className={"card-img"}/>
                    </Box>
                </Box>

                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/tms_statistics.png'} alt={"tms_statistics.png"} className={"card-img"}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        Zusätzlich zu seinen Kernfunktionen bietet TMS auch fortschrittliche Analysewerkzeuge, die den
                        Managern helfen, die Arbeitszeiten und die Produktivität der Mitarbeiter besser zu verstehen.
                        Durch
                        den Einsatz von Diagrammen und Berichten können die Nutzer leicht die Zeitverwendung
                        visualisieren
                        und Bereiche identifizieren, die Verbesserungen erfordern.
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>ZUGRIFFSKONTROLLE</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1,
                        gap: '.5rem'
                    }}>
                        <Typography>
                            TMS wurde mit einem Rollenbasierten Zugriffskontrollsystem entwickelt, um verschiedene
                            Nutzergruppen und ihre jeweiligen Berechtigungen zu verwalten.
                        </Typography>

                        <Typography><b>Human Resources (HR):</b> HR hat vollen Zugriff auf alle Daten und Funktionen im
                            System.</Typography>
                        <Typography><b>Teamleiter:</b> Sie haben Zugriff auf die Daten ihres eigenen Teams, um die
                            Leistung zu überwachen.</Typography>
                        <Typography><b>Mitarbeiter:</b> Sie können ihre eigenen Zeiterfassungsdaten einsehen und
                            bearbeiten, haben aber keinen Zugriff auf die Daten anderer.</Typography>
                    </Box>

                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/tms_teammanagement.png'} alt={"tms_teammanagement.png"} className={"card-img"}/>
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>FLEXIBILITÄT</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        flex: 2,
                        height: 'max-content',
                        flexDirection: {xs: 'column', md: 'row'},
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box>
                            <img src={'/tms_mobile_timesheet_new.png'} alt={"tms_mobile_timesheet_new.png"}
                                 className={"card-img"}/>
                        </Box>
                        <Box>
                            <img src={'/tms_mobile_statistics.png'} alt={"tms_mobile_statistics.png"}
                                 className={"card-img"}/>
                        </Box>
                        <Box>
                            <img src={'/tms_mobile_teammanagement.png'} alt={"tms_mobile_teammanagement.png"}
                                 className={"card-img"}/>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        Eine der Stärken von TMS ist seine einfache und intuitive Benutzeroberfläche, die das Ein- und
                        Auschecken sowie die Erfassung von Pausenzeiten erleichtert. Durch die Web-Basierte Software ist
                        sie auch auf verschiedenen Plattformen und Geräten zugänglich, was eine hohe Flexibilität
                        bietet.
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>SOFTWAREDESIGN</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        TMS wurde unter Anwendung der Wasserfallmethode in eimem 4 köpfigen Team, in einem Zeitraum von
                        6 Wochen, entwickelt. Damit folgte die Entwicklung einem traditionellen Ansatz in der
                        Softwareentwicklung, der eine lineare und sequenzielle Vorgehensweise beinhaltet.<br/>
                        Analyse, Design, Coden, Testen und Wartung.
                    </Box>
                    <Box sx={{flex: 2, height: 'max-content'}}>
                        {/*<img src={'/tms1.png'} alt={"tms1.png"} className={"card-img"}/>*/}
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>DATENBANK</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        flex: 2,
                        height: 'max-content',
                        flexDirection: {xs: 'column', md: 'row'},
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={'/tms_dbschema.png'} alt={"tms_dbschema.png"} className={"card-img"}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        TMS verwendet eine SQLite-Datenbank in Kombination mit SQLAlchemy, einem SQL Toolkit und ORM für
                        Python. SQLite bietet eine leichte, dateibasierte Datenbanklösung, während SQLAlchemy eine
                        abstrakte Schnittstelle für effiziente und flexible Datenzugriffe ermöglicht. Dieser Ansatz
                        erleichtert den Wechsel zu einer größeren, vollwertigen Datenbank, falls in Zukunft eine
                        Skalierung erforderlich wird.
                    </Box>
                </Box>

            </Box>
        </>
    )
}

export default Tms;