import './contact.css'
import './views.css'
import {ContactForm, Footer} from "../comp/comp";
import {Box, Button} from "@mui/material";
import React from "react";

const Header = () => {

    return (
        <>
            <Box
                component="span"
                sx={{
                    position: 'fixed',
                    background: 'black',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '25vw',
                    lineHeight: '20vw',
                    fontWeight: '400',
                    color: 'rgb(0,35,35)',
                    zIndex: -1,
                    fontFamily: "'Anonymous Pro', monospace"
                }}
            >
                CONTACT
            </Box>
            <Box sx={{
                margin: {xs: '6rem 1rem', md: '6rem 2rem'},
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: '100vh',
                color: 'white'
            }}>
                <Box sx={{maxWidth: '50rem'}}>
                    {/*<ContactForm/>*/}
                    <Button className="registration-btn"
                            onClick={() => window.location.href = "mailto:info@jbyte.de?subject=KontaktForm"}
                            variant={"outlined"}
                            color={"inherit"}>
                        Send Mail
                    </Button>
                </Box>
            </Box>
            <Footer/>
        </>
    )
}

export default Header;