import brace from "../dat/logo.svg"
import {ButtonColorInvert} from "../comp/comp";
import './welcome.css'

const Welcome = () => {
    return (
        <div className="welcome">
            <div className="welcome_div_left">
                <div className="welcome_div_left_content">
                    <h1 style={{margin: ".2rem 0", color: "#FFFFFF"}}>JONAS BURGER</h1>
                    <h3 style={{margin: ".2rem 0", color: "#FFFFFF"}}>technical computer scientist</h3>
                    <h6 style={{color: "gray", margin: ".2rem 0"}}>Embedded - Full Stack Developer</h6>
                    <ButtonColorInvert to="contact" text={"CONTACT"}/>
                </div>
            </div>
            <div className="welcome_div_right">
                <img src={brace} height="100%" alt="Code-Logo" className="welcome_logo"/>
            </div>
        </div>
    )
}

export default Welcome;