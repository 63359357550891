import {Article, Footer} from "../comp/comp";
import "./work.css"
import './views.css'
import {Box} from "@mui/material";

const Work = () => {

    return (
        <>
            <Box
                component="span"
                sx={{
                    position: 'fixed',
                    background: 'black',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '35vw',
                    lineHeight: '20vw',
                    fontWeight: '400',
                    color: 'rgb(0,35,35)',
                    zIndex: -1,
                    fontFamily: "'Anonymous Pro', monospace"
                }}
            >
                WORK
            </Box>
            <div className={"views"}>
                <Box sx={{paddingX: {xs: '1rem', sm: '2rem'} }}>
                    <Box sx={{width: '100%', display: 'grid', gap: {sm: '1rem', md: '2rem'}, mt: '3rem'}}>

                        {/* BURGER-BEES */}
                        <Article
                            img_path={"burger-bees.png"}
                            id={'work-article-burger-bees'}
                            title={'Burger Bees'}
                            subtitle={'Webshop - API, Fullstack'}
                            path={'burgerbees'}/>

                        {/* KEY-MAN */}
                        <Article
                            img_path={"keyman_contract.png"}
                            id={'work-article-key-man'}
                            title={'KEY MAN'}
                            subtitle={'Lizenzen Management system - API, Fullstack'}
                            path={'keyman'}/>


                        {/* PDS */}
                        <Article img_path={"pds1.png"}
                                 id={'work-article-pds'}
                                 title={'PDS'}
                                 subtitle={'Parking Deck Management System with - API, Fullstack'}
                                 path={'pds'}/>


                        {/* TMS */}
                        <Article img_path={"tms.png"}
                                 id={'work-article-tms'}
                                 title={'TMS'}
                                 subtitle={'Time Management / Tracking Software - Fullstack'}
                                 path={'tms'}/>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default Work;