import {Link} from "react-router-dom";
import './comp.css'

const ButtonColorInvert = ({to, text, target= null, href= null}) => {
    return (
        href ? <a href={href} target={target ? target : '_self'} className={'button-color-invert'}>{text}</a> :
        <Link to={{pathname: to}} target={target ? target : '_self'} className="button-color-invert">{text}</Link>
    )
}

export default ButtonColorInvert;