import {Footer} from "../comp/comp";
import "./work.css"
import './views.css'
import {Box} from "@mui/material";

const Imprint = () => {

    return (
        <>
            <Box className={"views"} sx={{background: 'black'}}>
                <h1 className={"heading"}>IMPRESSUM</h1>
                <div className={"basic-content"}>
                    <Box className={"width-restriction"}>
                        jByte<br/>
                        Jonas Burger<br/>
                        Olgastraße 4<br/>
                        73728 Esslingen<br/>
                        Germany<br/><br/>

                        Telefon: 0152 219569990<br/>
                        E-Mail: info@jbyte.de<br/><br/>

                        <span><b>Geschäftsführer:</b> Jonas Burger</span><br/><br/>

                        <span style={{fontWeight: 700}}>Verantwortlich für den Inhalt und Datenschutz: Jonas Burger</span><br/><br/>

                        Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG): Wir sind nicht bereit oder
                        verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                        teilzunehmen.<br/><br/>

                        <h3>HAFTUNGSAUSSCHLUSS</h3><br/>

                        Alle Informationen und Inhalte auf dieser Website wurden sorgfältig erstellt und überprüft, um eine
                        aktuelle, umfassende und fehlerfreie Darstellung zu gewährleisten. Trotz größter Sorgfalt kann es
                        jedoch zu Fehlern kommen, weshalb keine Garantie für Vollständigkeit, Richtigkeit, Aktualität und
                        dauerhafte Verfügbarkeit gegeben werden kann.<br/><br/>

                        Der Zugriff und die Nutzung dieser Website erfolgen auf eigene Verantwortung des Benutzers. Der
                        Betreiber dieser Webseite übernimmt keinerlei Haftung für Schäden, die durch den Zugang und/oder die
                        Nutzung dieser Website verursacht werden.<br/><br/>


                        <h3>DATENSCHUTZERKLÄRUNG</h3>
                        <h4>DATENSCHUTZ</h4>
                        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und halten uns strikt an die geltenden Datenschutzgesetze sowie an diese Datenschutzerklärung.
                        <br/>
                        Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                        <br/>
                        Bitte beachten Sie, dass die Übertragung von Daten im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

                        <h4>COOKIES</h4>
                        Unsere Website verwendet Cookies, um sie nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Endgerät abgelegt werden und von Ihrem Browser gespeichert werden.
                        <br/>
                        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle ablehnen oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.

                        <h4>SERVER-LOG-DATEIEN</h4>
                        Der Provider dieser Website erhebt und speichert automatisch Informationen in Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Diese Informationen umfassen:
                        <br/>
                        - Browsertyp und Browserversion<br/>
                        - Verwendetes Betriebssystem<br/>
                        - Referrer URL (die zuvor besuchte Seite)<br/>
                        - Hostname des zugreifenden Rechners (IP-Adresse)<br/>
                        - Uhrzeit der Serveranfrage<br/>

                        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns jedoch vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.

                        <h4>KONTAKTFORMULAR</h4>
                        Wenn Sie uns über das Kontaktformular Anfragen zusenden, werden Ihre Angaben aus dem Formular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.

                        <h4>SSL-VERSCHLÜSSELUNG</h4>
                        Unsere Website nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie beispielsweise Anfragen, die Sie an uns als Seitenbetreiber senden, ausschliesslich eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile Ihres Browsers mit "https://" beginnt und an dem Schloss-Symbol in Ihrer Browserzeile.

                        <h4>RECHT AUF AUSKUNFT, LÖSCHUNG, SPERRUNG</h4>
                        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger, den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.

                        <h4>WANN NUTZEN UND VERARBEITEN WIR PERSONENBEZOGENE DATEN?</h4>
                        Wir wissen, dass Ihnen der sorgfältige Umgang mit Ihren personenbezogenen Daten sehr wichtig ist. Deshalb nehmen wir den Datenschutz ernst und halten uns bei der Erhebung, Verarbeitung und Nutzung streng an die gesetzlichen Bestimmungen nach dem Bundesdatenschutzgesetz. In einigen Fällen benötigen wir personenbezogene Daten von Ihnen, und zwar:
                        <br/>
                        - Bei der Kontaktaufnahme mit uns: Wenn Sie mit uns in Kontakt treten, beispielsweise per E-Mail oder über das Kontaktformular, erfassen wir Ihre Kontaktdaten, um Ihre Anfragen zu bearbeiten und zu beantworten.
                        <br/>
                        Bitte beachten Sie, dass diese Datenschutzerklärung gelegentlich aktualisiert wird, um Änderungen in unseren Geschäftspraktiken, rechtlichen Anforderungen oder anderen Umständen Rechnung zu tragen. Überprüfen Sie daher regelmäßig diese Seite, um sicherzustellen, dass Sie mit den aktuellen Bestimmungen vertraut sind.
                        <br/>
                        Wenn Sie weitere Fragen zur Datenschutzerklärung haben, kontaktieren Sie uns bitte unter den angegebenen Kontaktdaten.
                        </Box>

                </div>
            </Box>
            <Footer/>
        </>
    )
}

export default Imprint;