import {NavLink} from "react-router-dom";
import './comp.css'

const Footer = () => {
    return (
        <div className={"footer"}>
            <div className={"footer-lines"}>
                <NavLink to={"/imprint"} className={"footer-link"}>Imprint</NavLink>
                <NavLink to={"/imprint?scroll=privacy"} className={"footer-link"}>Privacy</NavLink>
                <NavLink to={"/contact"} className={"footer-link"}>Contact</NavLink>
            </div>
            <div className={"footer-lines"}>
                <span>&copy; | Jonas Burger </span>
            </div>
        </div>
    )
}

export default Footer;