import {Box, Slide, useScrollTrigger} from "@mui/material";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import './header.css'
import logo from '../dat/logo.svg'

const Header = () => {
    const trigger = useScrollTrigger();
    const navigate = useNavigate();

    return (
        <>
            <div className={"header_darkener"}></div>
            <Slide appear={false} direction="down" in={!trigger}>
                <div className={"logo_line"}>
                <span className={"lines"}>
                    <img className={"header_logo"} src={logo} alt="bee1" onClick={() => navigate("/")}/>
                </span>
                </div>
            </Slide>
            <div className="header">
                <Box>
                    <NavLink to={"/work"} className={"nav-link"}>WORK</NavLink>
                    <NavLink to={"/contact"} className={"nav-link"}>CONTACT</NavLink>
                </Box>
            </div>
            <Outlet/>
        </>
    )
}

export default Header;