import {Box, ButtonBase, styled, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 'max-content',
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const Article = ({id, img_path, title, subtitle, path}) => {
    const nav = useNavigate();

    return (
        <Box sx={{
            display: {xs: 'grid', md: 'flex'},
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            height: '70vh',
            fontFamily: "'Anonymous Pro', monospace"
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: {xs: 'center', md: 'end', xl: 'center'},
                justifyContent: 'center',
                flex: 1
            }}>
                <h1>{title}</h1>
                <Typography component={'summary'}>{subtitle}</Typography>
            </Box>

            <Box sx={{flex: 2, height: 'max-content'}}>
                <ImageButton
                    focusRipple
                    onClick={() => nav('/work/' + path)}
                >
                    <img src={img_path} alt={"work-article" + id} className={"card-img"}/>
                    <ImageBackdrop className="MuiImageBackdrop-root" />
                    <Image>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                                position: 'relative',
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            }}
                        >
                            {'READ MORE'}
                            <ImageMarked className="MuiImageMarked-root" />
                        </Typography>
                    </Image>
                </ImageButton>
            </Box>
        </Box>
    )
}

export default Article;
