import {Box, Typography} from "@mui/material";
import {ButtonColorInvert} from "../comp";

const Pds = () => {

    return (
        <>
            <Box
                component="span"
                sx={{
                    position: 'fixed',
                    background: 'black',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '45vw',
                    lineHeight: '20vw',
                    fontWeight: '400',
                    color: 'rgb(0,35,35)',
                    zIndex: -1,
                    fontFamily: "'Anonymous Pro', monospace"
                }}
            >
                PDS
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: '1rem', md: '2rem'},
                margin: {xs: '4rem 1rem', md: '4rem 2rem'},
                color: 'white',
                fontSize: '1rem',
                fontFamily: "'Roboto', sans-serif"
            }}>
                <Typography variant={'h1'} sx={{fontFamily: "'Anonymous Pro', monospace"}}>PDS</Typography>
                <Typography variant={'subtitle1'} sx={{color: '#c4c9c9'}}>
                    Parking Deck Management System
                </Typography>
                <Typography sx={{
                    maxWidth: '50rem',
                    textAlign: 'block',
                }}>
                    PDS ist ein umfassendes Parkplatz-Management-System, das entworfen wurde,
                    um die Effizienz und den Betrieb von Parkplätzen zu verbessern.
                </Typography>

                <Box sx={{fontFamily: "'Anonymous Pro', monospace", display: 'flex'}}>
                    <ButtonColorInvert to="https://pds.jbyte.de"
                                       text={"JUST TRY IT"}
                                       target='_blank'
                                       href="https://pds.jbyte.de"/>
                </Box>

                <Typography>
                    <b>demo-username:</b> admin<br/><b>demo-password:</b> Password123
                </Typography>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>FUNKTIONEN</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        Das PDS kann verschiedene Arten von Parkplätzen verwalten, wie Standard, kleine,
                        Behindertenparkplätzen und Elektrofahrzeug-Ladestationen. Jeder Parkplatztyp hat spezifische
                        Eigenschaften und Regeln, die vom System berücksichtigt werden. Diese können bei bedarf
                        angepasst oder neue hinzugefügt werden.
                        <br/>
                        Darüber hinaus bietet das PDS eine monatliche Abrechnungsfunktion, die der Verwaltung eine
                        einfachere Buchhaltung ermöglicht.
                    </Box>

                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/pds_types.png'} alt={"pds_types.png"} className={"card-img"}/>
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>UI</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/pds_deck.png'} alt={"pds_deck.png"} className={"card-img"}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        Die Benutzeroberfläche bietet eine intuitive und benutzerfreundliche Möglichkeit, mit dem System
                        zu interagieren. Nutzer können leicht die Verfügbarkeit von Parkplätzen überprüfen,
                        Reservierungen vornehmen und Zahlungen abwickeln. Darüber hinaus können Administratoren die
                        Belegung der Parkplätze überwachen, Berichte generieren und das System konfigurieren.
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>API</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1,
                        gap: '.5rem'
                    }}>
                        Die API ermöglicht es Drittanbietern, auf bestimmte Funktionen des Systems zuzugreifen und
                        diese zu nutzen. Dies könnte zum Beispiel genutzt werden, um eine mobile App zu entwickeln,
                        die auf die Funktionen des PDS zugreift, oder um das System in andere Verwaltungssysteme zu
                        integrieren.
                    </Box>

                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/pds_docs.png'} alt={"pds_docs.png"} className={"card-img"}/>
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>TEAM</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        flex: 2,
                        height: 'max-content',
                        flexDirection: {xs: 'column', md: 'row'},
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={'/pds_revenue.png'} alt={"pds_revenue.png"} className={"card-img"}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        PDS wurde unter Anwendung der der Scrum Metode in eimem 4 köpfigen Team, entwickelt. Damit
                        folgte die Entwicklung einem agilen Ansatz in der Softwareentwicklung. Es wurden 3 Sprints je 2
                        Wochen durchgeführt.
                    </Box>
                </Box>

                <Box sx={{width: '100%', mt: {xs: '1rem', md: '2rem'}}}>
                    <Typography variant={'h4'}>DATENBANK</Typography>
                </Box>
                <Box sx={{
                    display: {xs: 'grid', md: 'flex'},
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: {xs: 'center', md: 'end', xl: 'center'},
                        justifyContent: 'center',
                        flex: 1
                    }}>
                        PDS verwendet eine SQLite-Datenbank in Kombination mit SQLAlchemy, einem SQL Toolkit und ORM für
                        Python. SQLite bietet eine leichte, dateibasierte Datenbanklösung, während SQLAlchemy eine
                        abstrakte Schnittstelle für effiziente und flexible Datenzugriffe ermöglicht. Dieser Ansatz
                        erleichtert den Wechsel zu einer größeren, vollwertigen Datenbank, falls in Zukunft eine
                        Skalierung erforderlich wird.
                    </Box>
                    <Box sx={{flex: 2, height: 'max-content'}}>
                        <img src={'/pds_dbschema.png'} alt={"pds_dbschema.png"} className={"card-img"}/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Pds;