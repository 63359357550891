import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material";
import {Contact, Imprint, Welcome, Work} from "./views/views";
import {Footer, Header, Keyman, Pds, Tms, BurgerBees} from "./comp/comp";
import ScrollToTop from "./helpers/ScrollToTop";

function App() {

    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        // typography: {
        //     fontFamily: "'Anonymous Pro', monospace"
        // },
    });

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    <Route index element={<><Header/><Welcome/></>}/>
                    <Route path={"contact"} element={<><Header/><Contact/></>}/>
                    <Route path={"work"} element={<><Header/></>}>
                        <Route index element={<><Work/><Footer/></>}/>
                        <Route path={"tms"} element={<><Tms/><Footer/></>}/>
                        <Route path={"pds"} element={<><Pds/><Footer/></>}/>
                        <Route path={"keyman"} element={<><Keyman/><Footer/></>}/>
                        <Route path={"burgerbees"} element={<><BurgerBees/><Footer/></>}/>
                    </Route>
                    <Route path={"imprint"} element={<><Header/><Imprint/></>}/>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
